import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name hard-hat
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxOGExIDEgMCAwIDAgMSAxaDE4YTEgMSAwIDAgMCAxLTF2LTJhMSAxIDAgMCAwLTEtMUgzYTEgMSAwIDAgMC0xIDF2MnoiIC8+CiAgPHBhdGggZD0iTTEwIDEwVjVhMSAxIDAgMCAxIDEtMWgyYTEgMSAwIDAgMSAxIDF2NSIgLz4KICA8cGF0aCBkPSJNNCAxNXYtM2E2IDYgMCAwIDEgNi02IiAvPgogIDxwYXRoIGQ9Ik0xNCA2YTYgNiAwIDAgMSA2IDZ2MyIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/hard-hat
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const HardHat: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z' }],
    ['path', { d: 'M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5' }],
    ['path', { d: 'M4 15v-3a6 6 0 0 1 6-6' }],
    ['path', { d: 'M14 6a6 6 0 0 1 6 6v3' }],
  ],
];

export default HardHat;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name signpost
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgMTN2OCIgLz4KICA8cGF0aCBkPSJNMTIgM3YzIiAvPgogIDxwYXRoIGQ9Ik0xOCA2YTIgMiAwIDAgMSAxLjQxNC41ODZsMi4yOTMgMi4yMDdhMSAxIDAgMCAxIDAgMS40MTRsLTIuMjcgMi4xODRhMiAyIDAgMCAxLTEuNzQyLjU4Nkw2IDEzYTIgMiAwIDAgMS0xLjQxNC0uNTg2bC0yLjI5My0yLjIwN2ExIDEgMCAwIDEgMC0xLjQxNGwyLjI5My0yLjIwN0EyIDIgMCAwIDEgNiA2eiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/signpost
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Signpost: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M12 13v8' }],
    ['path', { d: 'M12 3v3' }],
    [
      'path',
      {
        d: 'M18 6a2 2 0 0 1 1.414.586l2.293 2.207a1 1 0 0 1 0 1.414l-2.27 2.184a2 2 0 0 1-1.742.586L6 13a2 2 0 0 1-1.414-.586l-2.293-2.207a1 1 0 0 1 0-1.414l2.293-2.207A2 2 0 0 1 6 6z',
      },
    ],
  ],
];

export default Signpost;

import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name octagon-pause
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTVWOSIgLz4KICA8cGF0aCBkPSJNMTQgMTVWOSIgLz4KICA8cGF0aCBkPSJNMi41ODYgMTYuODcyQTIgMiAwIDAgMSAyIDE1LjQ1OFY4LjU0MmEyIDIgMCAwIDEgLjU4Ni0xLjQxNGw0LjU0Mi00LjU0MkEyIDIgMCAwIDEgOC41NDIgMmg2LjkxNmEyIDIgMCAwIDEgMS40MTQuNTg2bDQuNTQyIDQuNTQyQTIgMiAwIDAgMSAyMiA4LjU0MnY2LjkxNmEyIDIgMCAwIDEtLjU4NiAxLjQxNGwtNC41NDIgNC41NDJhMiAyIDAgMCAxLTEuNDE0LjU4Nkg4LjU0MmEyIDIgMCAwIDEtMS40MTQtLjU4NnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/octagon-pause
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const OctagonPause: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 15V9' }],
    ['path', { d: 'M14 15V9' }],
    [
      'path',
      {
        d: 'M2.586 16.872A2 2 0 0 1 2 15.458V8.542a2 2 0 0 1 .586-1.414l4.542-4.542A2 2 0 0 1 8.542 2h6.916a2 2 0 0 1 1.414.586l4.542 4.542A2 2 0 0 1 22 8.542v6.916a2 2 0 0 1-.586 1.414l-4.542 4.542a2 2 0 0 1-1.414.586H8.542a2 2 0 0 1-1.414-.586z',
      },
    ],
  ],
];

export default OctagonPause;
